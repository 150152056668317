<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="标签标题" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入标签标题"></a-input>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="16">
						<a-button type="primary" style="margin-right: 10px;">导出</a-button>
						<a-button type="primary">批量发货</a-button>
					</a-col>
					<a-col :span="8" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

			<div style="margin-top: 10px;">
				<a-tabs v-model:activeKey="formState.status" type="card" @change="onSearch">
					<a-tab-pane :key="0" tab="全部"></a-tab-pane>
					<a-tab-pane :key="1" tab="待支付"></a-tab-pane>
					<a-tab-pane :key="2" tab="待发货"></a-tab-pane>
					<a-tab-pane :key="3" tab="待收货"></a-tab-pane>
					<a-tab-pane :key="4" tab="待使用"></a-tab-pane>
					<a-tab-pane :key="5" tab="已使用"></a-tab-pane>
					<a-tab-pane :key="6" tab="已关闭"></a-tab-pane>
				</a-tabs>
				<cTable ref="cTable" style="padding: 0;" :searchData="searchData" :columns="columns" :isPage="true" :isRequest="true" :requestFun="getPerformOrderList" :scroll="{ x: 3500 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'showStartTime'">
							{{ transDateTime(record.showStartTime) }}
						</template>
						<template v-if="column.key === 'user'">
							<div>
								<div>用户id：{{ record.user.memberId || '-' }}</div>
								<div>用户名称：{{ record.user.nickname || '-' }}</div>
								<div>用户手机号：{{ record.user.phone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'payType'">
							<div>
								<a-tag v-if="record.payType === 'wx_lite'" color="#87d068">微信支付</a-tag>
								<a-tag v-if="record.payType === 'alipay'" color="#2db7f5">支付宝支付</a-tag>
								<a-tag v-if="record.payType === 'card'" color="#f50">一卡通支付</a-tag>
							</div>
						</template>
						<template v-if="column.key === 'status'">
							{{ transStatus(record.status) }}
						</template>
						<template v-if="column.key === 'sendInfo'">
							<div v-if="record.deliveryType === 3">
								<div>物流公司：{{ record.logisticsCompany }}</div>
								<div>物流单号：{{ record.logisticsNo }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'afterInfo'">
							<div v-if="record.afterSale">
								<div>售后状态：{{ transRefundStatus(record.afterSale.refundStatus) }}</div>
								<div>服务费：￥{{ record.afterSale.totalServiceFee }}</div>
								<div>退款金额：￥{{ record.afterSale.refund }}</div>
								<div>发起端：{{ ['小程序', '后台', '柜台'][record.afterSale.refundUserType - 1] }}</div>
								<div>发起时间：{{ transDateTime(record.afterSale.createTime) }}</div>
								<div v-if="record.afterSale.processName">审批人：{{ record.afterSale.processName }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button>操作 <Icon icon="DownOutlined"></Icon></a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSendOrder(record)">
											<a-menu-item>
												发货
											</a-menu-item>
										</div>
										<div @click="onAfter(record)">
											<a-menu-item>
												发起售后
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</cTable>
			</div>

			<a-modal v-model:visible="showSendGoods" destroyOnClose title="发货信息" width="500px" @ok="onSendOrderSubmit">
				<a-spin :spinning="loading">
					<a-form ref="sendForm" scrollToFirstError :model="modelRef" name="sendForm" :labelCol="{span: 5}"
						:wrapperCol="{span: 15 }">
						<a-form-item label="快递公司" name="logisticsCompany" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<logisticsCompany v-model:value="modelRef.logisticsCompany" placeholder="请选择物流公司"></logisticsCompany>
						</a-form-item>

						<a-form-item label="快递单号" name="logisticsNo" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="modelRef.logisticsNo" :precision="0" placeholder="请输入快递单号"></a-input>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>

			<a-modal v-model:visible="showAfterModal" destroyOnClose title="售后" width="500px">
				<a-spin :spinning="loading">
					<a-form ref="afterForm" scrollToFirstError :model="afterModelRef" name="afterForm"
						:labelCol="{span: 5}" :wrapperCol="{span: 15 }">
						
						<a-form-item label="退款金额" name="price" :rules="[{required: true, message:'必填项不允许为空'}]">
							<a-input-number v-model:value="afterModelRef.price"></a-input-number>
						</a-form-item>
						
						<a-form-item label="备注信息" name="info" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-textarea v-model:value="afterModelRef.info"></a-textarea>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>

	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import logisticsCompany from '@/components/logisticsCompany/index.vue';
	import { getPerformOrderList } from '@/service/modules/perform.js';
	import { sendOrderBase } from '@/service/modules/mall.js';
	export default {
		components: {
			Icon,
			cTable,
			logisticsCompany
		},
		data() {
			return {
				showSendGoods: false,
				showAfterModal: false,
				loading: false,
				searchData: {},
				modelRef: {},
				afterModelRef: {},
				formState: {
					sale: 0
				},
				columns: [{
					title: '演出',
					dataIndex: 'performName',
				}, {
					title: '订单号',
					dataIndex: 'orderNo',
					width: 170
				}, {
					title: '场次时间',
					key: 'showStartTime'
				}, {
					title: '票档',
					dataIndex: 'ticketName',
				}, {
					title: '用户信息',
					key: 'user',
					width: 220
				}, {
					title: '票号'
				}, {
					title: '座位数',
					dataIndex: 'ticketNum',
					width: 100
				}, {
					title: '已分配座位数',
					width: 180
				}, {
					title: '未分配座位数',
					width: 180
				}, {
					title: '出票状态'
				}, {
					title: '票获得方式'
				}, {
					title: '赠送用户'
				}, {
					title: '票状态'
				}, {
					title: '核销时间'
				}, {
					title: '核销人'
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getPerformOrderList: getPerformOrderList,
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData(true);
			},
			getData(isReset) {
				if (isReset) {
					// 重置分页查询
					this.$refs.cTable.toQuery();
				} else {
					// 不重置分页查询
					this.$refs.cTable.refQuery();
				}
			},
			onAfter(item) {
				this.afterModelRef = {
					id: item.id
				}
				this.showAfterModal = true;
			},
			onSendOrder(item) {
				this.modelRef = {
					id: item.id,
					logisticsCompany: undefined,
					logisticsNo: ''
				}
				this.showSendGoods = true;
			},
			onSendOrderSubmit() {
				this.$refs.sendForm.validate().then(() => {
					this.$confirm({
						title: '提示',
						content: '确定发货吗？',
						onOk: async ()=> {
							this.loading = true;
							let ret = await sendOrderBase(this.modelRef)
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('发货成功');
								this.getData();
							}
						}
					})
				})
			},
			transRefundStatus(status) {
				let value = '';
				switch(status) {
					case 1:
						value = '审核中';
						break;
					case 2:
						value = '待用户发货';
						break;
					case 3:
						value = '用户已发货';
						break;
					case 4:
						value = '审核不通过';
						break;
					case 5:
						value = '用户已撤回';
						break;
					case 6:
						value = '售后完成';
						break;
					case 7:
						value = '改签成功';
						break;
					default:
						value = '-';
						break;
				}
				return value;
			},
			transStatus(status) {
				let value = '';
				switch(status) {
					case 'SYSTEM_CANCEL':
						value = '已关闭'
						break;
					case 'CUSTOMER_CANCEL':
						value = '已关闭'
						break;
					case 'WAIT_BUYER_PAY':
						value = '待付款'
						break;
					case 'WAIT_SELLER_SEND_GOODS':
						value = '待发货'
						break;
					case 'WAIT_CHECK':
						value = '待核销'
						break;
					case 'WAIT_BUYER_CONFIRM_GOODS':
						value = '待确认收货'
						break;
					case 'WAIT_COMMENT':
						value = '待评价'
						break;
					case 'TRADE_FINISH':
						value = '已完成'
						break;
					case 'AFTERSALE_FINISH':
						value = '已售后'
						break;
					default:
						value = '-';
						break;
				}
				return value;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>